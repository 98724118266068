/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable padded-blocks */
import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import moment from 'moment';
// eslint-disable-next-line import/no-named-as-default
import _ from 'lodash';
import crud from '../../../api/crud';
import { useAlert } from '../../providers/AlertProvider';
import RedemptionTbbForm from './RedemptionTbbForm';
import RedemptionQrView from './RedemptionQrView';
import sendRedemptionEmail from '../../../utils/sendRedemptionEmail';
import FromAddressModal from '../../global/FromAddressModal/FromAddressModal';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import LoadingComponent from './Components/LoadingComponent';
import RegisterTbbContainer from '../../trashie/containers/RegisterTbbContainer';
import RegisterTbb from '../../trashie/global/RegisterTbb';
import RegisterEmail from '../../trashie/global/RegisterEmail';
import Tbbpin from '../../trashie/global/TbbPin';
import { isNullOrEmpty } from '../../trashie/utils/utils';
import { emailFormat } from '../../trashie/utils/emailFormatValidator';

import Style from './RedemptionLandingPage.module.scss';
import TbbRewardPage from '../../trashie/global/RegisterTbb/RewardCouponScreen/TbbRewardPage';
import ConfirmAddress from '../../trashie/global/ConfirmAddress/ConfirmAddress';
import useTbbAmountsExchange from '../../../hooks/useTbbAmountsExchange';

import { qrIsExpired, isExpiredLabel, shippingProviderStatus } from '../../trashie/utils/easyPost';
import { postShippingLabelRegenerate } from '../../trashie/utils/service';
import { FORDAYS_STORE_ID } from '../../trashie/config/config';
import { REWARD_LINK_RULE } from '../../trashie/utils/couponRules';

const { container, main } = Style;

const NO_ADDRESS_DATA_FOUND = 'no address found';
const CODE_LENGTH = 13;

const getDefaultAddress =
  (adrData) => adrData?.addresses?.find(a => a.id === adrData.defaultAddress?.id);

const isValidAddress = (address) => !isNullOrEmpty(address.address1)
  && !isNullOrEmpty(address.firstName) && !isNullOrEmpty(address.lastName)
  && !isNullOrEmpty(address.zip) && !isNullOrEmpty(address.city)
  && !isNullOrEmpty(address.provinceCode);

const getValidAddressFromArray =
  (adrData) => adrData?.addresses?.find(a => isValidAddress(a));

const RedemptionLandingPage = () => {
  const { user } = useFirebaseAuth();
  const setAlert = useAlert();
  const [redemptionCode, setRedemptionCode] = useState(null);
  const [firstView, setFirstView] = useState(true);
  const [attempts, setAttempts] = useState(5);
  const [isValidPin, setIsValidPin] = useState(true);
  const [isValidCode, setIsValidCode] = useState(null);
  const [emailInput, setEmailInput] = useState('');
  const [requirePin, setRequirePin] = useState(true);
  const [codeFound, setCodeFound] = useState(true);
  const [codeFoundMessage, setCodeFoundMessage] = useState(null);
  const [pinValues, setPinValues] = useState(Array(6).fill(''));
  const [validPinValues, setValidPinValues] = useState(false);
  const [tab, setTab] = useState('RedemptionTbbForm');
  const [qrCodeImgUrl, setQrCodeImgUrl] = useState('');
  const [printLabelUrl, setPrintLabelUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [qrFailed, setQRFailed] = useState(false);
  const [isTbbFormBtnDisabled, setIsTbbFormBtnDisabled] = useState(true);
  const [couponData, setCouponData] = useState({});
  const [copied, setCopied] = useState(false);
  const [fromAddress, setFromAddress] = useState({});
  const [storeConfig, setStoreConfig] = useState({});
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [registerFlowStep, setRegisterFlowStep] = useState(0);
  const [creditAmt, setCreditAmt] = useState(0);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isAQrExpired, setIsAQrExpired] = useState(false);
  const { tbbAmount } = useTbbAmountsExchange();
  const [expirationLabelDate, setExpirationLabelDate] = useState(null);

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (redemptionCode === null) {
      let newRedemptionCode = null;
      const urlCode = query.get('code');

      if (!urlCode) {
        setAlert({
          type: 'notification',
          message: 'Improper Redemption ID. Please close out or navigate away from this page',
        });
      } else {
        const splitCode = urlCode.split('-')[0] ?? '';

        if (splitCode.length === CODE_LENGTH && Number(splitCode)) {
          newRedemptionCode = urlCode;
        } else if (urlCode.length === CODE_LENGTH && Number(urlCode)) {
          newRedemptionCode = urlCode;
          // eslint-disable-next-line no-restricted-globals
        } else if (isNaN(urlCode)) {
          try {
            newRedemptionCode = window.atob(urlCode);
          } catch (e) {
            newRedemptionCode = null;
          }
        }
      }

      setRedemptionCode(newRedemptionCode);
      setIsLoading(false);
    }

    // Start Temporal Maintenance message
    const now = moment();
    const limit = moment(new Date('2024-02-02T23:59:00.000-08:00')); // PST UTC-8

    if (now.isBefore(limit)) {
      setAlert({
        type: 'notification',
        message: (
          <span
            style={{
              display: 'block',
              color: '#000',
              fontFamily: 'GT Maru',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '150%',
            }}
          >
            Scheduled maintenance on Feb 02, from 11:00 pm to 11:30 pm PST.
            We apologize for any inconvenience.
          </span>
        ),
      });
    }
    // End Temporal Maintenance message
  }, []);

  const csSetFromAddress = async (csAddr) => {
    setFromAddress(csAddr);
  };

  const getAndSetStores = useCallback(async (storeId) => {
    setIsLoading(true);
    try {
      const fetchedStoreData = await crud.get({
        path: '/stores',
      });
      const whiteLabelStore = fetchedStoreData.find(({ uid }) => uid === storeId);
      let whiteLabelStoreObj;
      if (whiteLabelStore) {
        whiteLabelStoreObj = {
          ...whiteLabelStore.meta,
          storeType: whiteLabelStore.storeType,
          id: whiteLabelStore.id,
          uid: whiteLabelStore.uid,
          couponRule: whiteLabelStore.couponRule,
        };
        document.body.style.backgroundColor = whiteLabelStoreObj.color2;
        if (!whiteLabelStore.meta.registerTbbFlow) {
          setIsValidCode(false);
        }
        setStoreConfig(whiteLabelStoreObj);
        setCreditAmt(whiteLabelStoreObj?.registerTbbFlow?.rewardStep?.cashValue);
      }

    } catch (error) {
      setAlert({
        type: 'notification',
        message: `Code 2: ${error.message}`,
      });
    } finally {
      setIsLoading(false);
    }
  });

  const getExistingRedemption = useCallback(async () => {
    setIsLoading(true);
    let fetchedExistingRedemption = await crud.get({
      path: `/redemptions/${redemptionCode}`,
    });
    const isQrExpired = qrIsExpired(
      fetchedExistingRedemption.redeemedAt,
      fetchedExistingRedemption.shipmentStatus,
      fetchedExistingRedemption.shippingLabelExpiresAt,
    );
    setIsAQrExpired(isQrExpired);

    if (isExpiredLabel(fetchedExistingRedemption.shippingLabelExpiresAt) &&
    (fetchedExistingRedemption.shipmentStatus === shippingProviderStatus.unknown ||
    fetchedExistingRedemption.shipmentStatus === shippingProviderStatus.pre_transit)
    ) {
      try {
        const { data } = await postShippingLabelRegenerate(redemptionCode);
        if (!_.isEmpty(data)) {
          fetchedExistingRedemption = data;
        }
      } catch (error) {
        setAlert({
          type: 'notification',
          message: `Code 2: ${error}`,
        });
      }

    }

    setCreditAmt(fetchedExistingRedemption.creditAmt);

    if (
      fetchedExistingRedemption?.labeldata
      && !JSON.parse(fetchedExistingRedemption.labeldata).error
    ) {
      const labelData = JSON.parse(fetchedExistingRedemption.labeldata);
      const qrFormObj = labelData.forms.find(({ form_type: formType }) => formType === 'label_qr_code');
      const printLabelUrlTmp = labelData?.postage_label?.label_pdf_url ?
        labelData.postage_label.label_pdf_url :
        labelData?.postage_label?.label_url;
      setExpirationLabelDate(fetchedExistingRedemption?.shippingLabelExpiresAt);
      setCouponData({
        couponCode: fetchedExistingRedemption?.couponData,
        summary: redemptionCode.includes('-') ? redemptionCode.split('-')[1] : '',
      });
      setQrCodeImgUrl(qrFormObj.form_url);
      setPrintLabelUrl(printLabelUrlTmp);
      setFirstView(false);
      setEmailInput(fetchedExistingRedemption?.redeemedByEmail ?? '');
      setIsNewUser(fetchedExistingRedemption?.redeemedByNew ?? false);
      setTab('RedemptionQrView');
      setRegisterFlowStep(5);
      setIsLoading(false);
    } else if (
      fetchedExistingRedemption?.labeldata
      && JSON.parse(fetchedExistingRedemption.labeldata).error
    ) {
      crud.post({
        path: `/redemptions/revertcode/${fetchedExistingRedemption.uid}`,
      });
    }
    setIsLoading(false);
  });

  const validateCode = useCallback(async () => {
    setIsValidCode(false);
    setIsLoading(true);

    if (!redemptionCode) {
      setIsLoading(false);
      return;
    }

    try {
      const redemptionResult = await crud.get({
        path: `/redemptions/validate/${redemptionCode}`,
      });

      if (!redemptionResult?.found) {
        setCodeFound(false);
        setCodeFoundMessage(redemptionResult?.message ?? 'An error has occurred when trying to redeem your code, please contact the store');
      } else {
        setCodeFound(true);
        if (!redemptionResult?.requirePIN) {
          setRequirePin(false);
        }
      }

      if (codeFound) {
        if (redemptionResult?.attempts === 0) {
          setAttempts(redemptionResult?.attempts);
        }

        setIsValidCode(true);
        await getAndSetStores(redemptionResult.storeId);
        await getExistingRedemption();
      }
    } catch (error) {
      if (error.error) {
        // A variable is assigned the value of found found in the API response
        const redmCodeFound = error?.found ?? false;
        const msgError = error?.message ?? '';
        setCodeFoundMessage('An error has occurred when trying to redeem your code, please contact the support.');
        setIsValidCode(false);

        // If the code does not exist, it will enter the first condition and display the not found
        // message, if found is true it will display the normal view unless it has no more attempts.
        if (!redmCodeFound) {
          setCodeFoundMessage('The bag code you are trying to redeem was not found in our records, if you think this is an error please contact support.');
        } else if (redmCodeFound && msgError.includes('FD-R-011')) {
          getExistingRedemption();
          setCodeFoundMessage('You have tried to redeem the code more than 5 times with a wrong PIN, please contact support.');
        } else {
          setAlert({
            type: 'notification',
            message: `Code 2: ${msgError}`,
          });
        }
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [redemptionCode]);

  const handleQrFormButtonClick = () => {
    setRegisterFlowStep(6);
    setTab('RedemptionCouponView');
  };

  useEffect(() => {
    if (redemptionCode && isValidCode === null) {
      validateCode();
    }
  }, [redemptionCode]);

  const getAddressByEmail = async () => {
    const addressData = await crud.get({
      path: `/addresses/${encodeURIComponent(emailInput)}`,
    });
    return addressData;
  };

  const handleSubmit = async (e, addressObject) => {
    e.preventDefault();
    if (emailFormat(emailInput)) {
      setIsValidEmail(true);
    } else {
      setAlert({
        type: 'passive',
        message: 'Improperly formatted email. Please try again.',
      });
      setIsValidEmail(false);
      return;
    }
    try {
      setIsLoading(true);
      if (
        !isValidAddress(addressObject)
      ) {
        let address;
        try {
          const adrData = await getAddressByEmail();
          if (adrData === NO_ADDRESS_DATA_FOUND) {
            address = adrData;
          } else {
            const defaultAdr = getDefaultAddress(adrData);
            if (isValidAddress(defaultAdr)) {
              address = getValidAddressFromArray(adrData);
            } else {
              address = defaultAdr;
            }
          }
          if (
            adrData === NO_ADDRESS_DATA_FOUND ||
            !address ||
            Object.keys(address) === 0 ||
            !isValidAddress(address)
          ) {
            csSetFromAddress({
              firstName: address.firstName,
              lastName: address.lastName,
              address1: address.address1,
              address2: address.address2 || '',
              city: address.city,
              provinceCode: address.provinceCode,
              zip: address.zip,
            });
            setRegisterFlowStep(4);
            setTab('RedemptionAddressForm');
          } else {
            csSetFromAddress({
              firstName: address.firstName,
              lastName: address.lastName,
              address1: address.address1,
              address2: address.address2 || '',
              city: address.city,
              provinceCode: address.provinceCode,
              zip: address.zip,
            });
          }
        } catch (error) {
          // do nothing
        }
        await setIsLoading(false);
        return;
      }

      let redemptionRes;
      try {
        redemptionRes = await crud.post({
          path: '/redemptions',
          body: {
            data: {
              id: redemptionCode,
              pin: pinValues.join(''),
              email: emailInput,
              redemptionStatus: 'claimed',
              creditAmt: storeConfig.uid === 1 ? tbbAmount : 20,
              storeId: storeConfig.id || FORDAYS_STORE_ID,
              storeUid: storeConfig.uid || 1,
              fromAddress: addressObject,
            },
          },
        });

        redemptionRes = (typeof redemptionRes === 'object') ? redemptionRes : JSON.parse(redemptionRes);
        if (redemptionRes?.registerResult) {
          setCreditAmt(redemptionRes.registerResult.creditAmt);
          setEmailInput(redemptionRes.registerResult?.redeemedByEmail ?? '');
          setIsNewUser(redemptionRes.registerResult?.redeemedByNew ?? false);
          setExpirationLabelDate(redemptionRes.registerResult?.shippingLabelExpiresAt);
        }
        if (redemptionRes.invalid) {
          setIsLoading(false);
          setIsValidPin(false);
          setIsValidCode(true);
          setAttempts(redemptionRes.attempts);
          if (redemptionRes.attempts === 0) {
            validateCode();
          }

          return;
        }
      } catch (error) {
        setIsLoading(false);
        setQRFailed(true);
        /* setAlert({
          type: 'alert',
          message: <center>Oops... An internal error has occurred,
           please try again or contact support. <br /><strong>Error
           code:</strong> {error?.message || ''}</center>,
        }); */
        return;
      }

      if (redemptionRes === 'noFromAddress') {
        setRegisterFlowStep(4);
        setTab('RedemptionAddressForm');
        return;
      }

      setFirstView(false);
      setTab('RedemptionQrView');
      setRegisterFlowStep(5);

      let qrFormObj;
      try {
        qrFormObj = await redemptionRes.forms?.find(
          ({ form_type: formType }) => formType === 'label_qr_code',
        );
      } catch (error) {
        setIsLoading(false);
        setQRFailed(true);
        setAlert({
          type: 'alert',
          message: `Code 2: ${error.message}`,
        });
      }

      const printLabelUrlTmp = redemptionRes?.postage_label?.label_pdf_url ?
        redemptionRes?.postage_label?.label_pdf_url :
        redemptionRes?.postage_label?.label_url;

      setCouponData(redemptionRes?.couponData);

      if (qrFormObj) {
        setQrCodeImgUrl(qrFormObj.form_url);
        setPrintLabelUrl(printLabelUrlTmp);
        try {
          await sendRedemptionEmail(redemptionCode, user, emailInput);
        } catch (error) {
          // do nothing
        }
      } else {
        setQRFailed(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setAlert({
        type: 'alert',
        message: `Code 3: ${error.message}`,
      });
      setIsLoading(false);
    }
  };

  const handleCopy = () => {
    if (storeConfig?.couponRule === REWARD_LINK_RULE) {
      window.open(couponData?.couponCode, '_blank');
    } else {
      navigator.clipboard.writeText(couponData?.couponCode);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, '1850');
    }
  };

  useEffect(() => {
    setIsTbbFormBtnDisabled(!emailInput || (requirePin && !validPinValues));
  }, [emailInput, validPinValues]);

  if (isLoading) {
    return (
      <LoadingComponent initialDate={new Date()} />
    );
  }

  const handlePinStep = () => {
    setTab('RegisterEmail');
    setRegisterFlowStep(2);
  };

  const handleZeroStep = () => {
    setFirstView(false);
    if (requirePin) {
      setTab('RegisterPin');
      setRegisterFlowStep(1);
    } else {
      handlePinStep();
    }

  };

  const handleEmailStep = async () => {
    const addressData = await getAddressByEmail();
    if (addressData === NO_ADDRESS_DATA_FOUND) {
      setTab('RedemptionAddressForm');
      setRegisterFlowStep(4);
    } else {
      let address;
      const defaultAdr = getDefaultAddress(addressData);
      if (!isValidAddress(defaultAdr)) {
        address = getValidAddressFromArray(addressData);
        if (!address) {
          setTab('RedemptionAddressForm');
          setRegisterFlowStep(4);
          return;
        }
      } else {
        address = defaultAdr;
      }
      setFromAddress({
        firstName: address.firstName,
        lastName: address.lastName,
        address1: address.address1,
        address2: address.address2 || '',
        city: address.city,
        provinceCode: address.provinceCode,
        zip: address.zip,
      });
      setTab('ConfirmAddressForm');
      setRegisterFlowStep(3);
    }
  };

  const handleAddressStep = async (e, addressObject) => {
    setTab('RedemptionQrView');
    setRegisterFlowStep(5);
    await handleSubmit(e, addressObject);
  };

  return (
    <div
      style={{
        background: registerFlowStep === 0 ?
          storeConfig?.registerTbbFlow?.landingPageStep?.bgColor :
          storeConfig?.registerTbbFlow?.bgColor,
      }}
      className={main}
    >
      <Container
        className={container}
      >
        <RegisterTbbContainer
          pin={pinValues.join('')}
          email={emailInput}
          qrFailed={qrFailed}
          registerCode={redemptionCode}
          currentStep={registerFlowStep}
          storeConfig={storeConfig}
          tab={tab}
        >
          <Row className="align-items-center justify-content-center remp-land-tabs">
            {firstView && (
              <RegisterTbb
                isValidCode={isValidCode}
                handleOnclick={handleZeroStep}
                storeConfig={storeConfig}
                registerCode={redemptionCode}
              />
            )}

            {!firstView && (
              <Tabs activeKey={tab} className="border-0">
                <Tab title={false} eventKey="RegisterPin">
                  <Tbbpin
                    storeConfig={storeConfig}
                    handleOnclick={handlePinStep}
                    registerCode={redemptionCode}
                    pinValues={pinValues}
                    setPinValues={(values) => {
                      setPinValues(values);
                      setValidPinValues(values.filter(value => value !== '').length === 6);
                    }}
                  />
                </Tab>
                <Tab title={false} eventKey="RegisterEmail">
                  <RegisterEmail
                    storeConfig={storeConfig}
                    email={emailInput}
                    onEmailChange={(email) => setEmailInput(email.toLowerCase())}
                    handleEmailStep={handleEmailStep}
                    creditAmt={creditAmt}
                  />
                </Tab>
                <Tab title={false} eventKey="RedemptionTbbForm">
                  <RedemptionTbbForm
                    isValidPin={isValidPin}
                    attempts={attempts}
                    codeFound={codeFound}
                    codeFoundMessage={codeFoundMessage}
                    requirePin={requirePin}
                    pinValues={pinValues}
                    setPinValues={(values) => {
                      setPinValues(values);
                      setValidPinValues(values.filter(value => value !== '').length === 6);
                    }}
                    storeConfig={storeConfig}
                    redemptionCode={redemptionCode}
                    emailInput={emailInput}
                    isTbbFormBtnDisabled={isTbbFormBtnDisabled}
                    onSubmit={handleSubmit}
                    onEmailInputChange={(e) => setEmailInput(e.target.value.toLowerCase())}
                    address={fromAddress}
                    isValidEmail={isValidEmail}
                    onEditAddress={() => {
                      setRegisterFlowStep(4);
                      setTab('RedemptionAddressForm');
                    }}
                  />
                </Tab>
                <Tab
                  title={false}
                  eventKey="ConfirmAddressForm"
                >
                  <ConfirmAddress
                    storeConfig={storeConfig}
                    address={fromAddress}
                    onEditAddress={() => {
                      setRegisterFlowStep(4);
                      setTab('RedemptionAddressForm');
                    }}
                    onContinue={(e) => {
                      handleAddressStep(e, fromAddress);
                    }}
                  />
                </Tab>
                <Tab title={false} eventKey="RedemptionAddressForm">
                  <FromAddressModal
                    storeConfig={storeConfig}
                    address={fromAddress}
                    setAddress={csSetFromAddress}
                    handleAddressStep={() => {
                      setRegisterFlowStep(3);
                      setTab('ConfirmAddressForm');
                    }}
                  />
                </Tab>
                <Tab title={false} eventKey="RedemptionQrView">
                  <RedemptionQrView
                    qrFailed={qrFailed}
                    storeConfig={storeConfig}
                    qrCodeImgUrl={qrCodeImgUrl}
                    printLabelUrl={printLabelUrl}
                    onQrFormButtonClick={handleQrFormButtonClick}
                    isAQrExpired={isAQrExpired}
                    expirationLabelDate={expirationLabelDate}
                  />
                </Tab>
                <Tab title={false} eventKey="RedemptionCouponView">
                  {tab === 'RedemptionCouponView' ? (
                    <TbbRewardPage
                      creditAmt={creditAmt}
                      fromAddress={fromAddress}
                      storeConfig={storeConfig}
                      couponData={couponData}
                      copied={copied}
                      handleCopy={handleCopy}
                      emailInput={emailInput}
                      isNewUser={isNewUser}
                    />
                  ) : null}
                </Tab>
              </Tabs>
            )}
          </Row>
        </RegisterTbbContainer>
      </Container>
    </div>
  );
};

export default RedemptionLandingPage;
