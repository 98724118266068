import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { EMAIL_SEARCH_PARAM, PROMO_ID_PARAM, ROUTE_HOME } from '../../config/routes';
import { EVENT_STREAM_APP_TYPES, EVENT_STREAM_EVENT_TYPES } from '../../config/service';
import Input from '../../global/InputNew';
import trashieCoinLogo from '../../images/trashie-coin-logo.svg';
import { getGenericError } from '../../utils/errors';
import { getSearchParam } from '../../utils/routes';
import { postSubscribeStatus, sendEventStream } from '../../utils/service';

import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import GoogleButton from '../../global/GoogleButton';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../global/Button';
import DotsLoader from '../../global/DotsLoader';
import DisclaimerPublic from '../../global/DisclaimerPublic';
import OptIn from '../../global/OptIn';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../../config/externalURL';
import { signInWithGoogle, createNewUser } from '../../../../utils/auth';

import './Signup.scss';

const Signup = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { [PROMO_ID_PARAM]: promoParam } = useParams();
  const { isSignedIn, isLoading: isLoadingFirebaseAuth, userData } = useFirebaseAuth();

  const query = new URLSearchParams(search);
  const emailParam = getSearchParam(query, EMAIL_SEARCH_PARAM);
  const promoId = getSearchParam(query, PROMO_ID_PARAM) || promoParam;

  const [email, setEmail] = useState(emailParam);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordMessageError, setPasswordMessageError] = useState('');
  const [passwordConfirmMessageError, setPasswordConfirmMessageError] = useState('');
  const [passWordInstructions, setPassWordInstructions] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isRegistering, setIsRegistering] = useState(false);
  const [optIn, setOptIn] = useState(true);

  const onFinishProcess = useCallback(async (userRegistered, subscribeEmail) => {
    if (userRegistered) {
      await postSubscribeStatus(subscribeEmail, optIn);

      await sendEventStream({
        event: EVENT_STREAM_EVENT_TYPES.USER_REGISTERED,
        application: EVENT_STREAM_APP_TYPES.TRASHIE,
        data: {
          ...(promoId && { promoId }),
        },
      });
    }

    history.push({ pathname: ROUTE_HOME, search });
    window.parent.postMessage('close', '*');
  }, [history, search, promoId, optIn]);

  const handleRegistration = useCallback(async () => {
    setIsRegistering(true);

    if (!email || !password || !confirmPassword) {
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      setErrorMessage('');
      setIsLoading(true);
      await createNewUser(email, password);

      window.gtag?.('event', 'sign_up', { method: 'manual' });
      window.fbq?.('trackCustom', 'sign_up');

      await onFinishProcess(true, email);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(error?.message || getGenericError());
      setIsLoading(false);
    }
  }, [email, password, confirmPassword, onFinishProcess]);

  const handleSignInWithGoogle = useCallback(async () => {
    setIsRegistering(true);

    try {
      setErrorMessage('');
      setIsLoading(true);

      const user = await signInWithGoogle();

      if (user?.additionalUserInfo?.isNewUser) {
        window.gtag?.('event', 'sign_up', { method: 'google' });
        window.fbq?.('trackCustom', 'sign_up');
      }

      await onFinishProcess(
        user?.additionalUserInfo?.isNewUser,
        user?.additionalUserInfo?.profile?.email,
      );
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(error?.message || getGenericError());
      setIsLoading(false);
    }
  }, [onFinishProcess]);

  const handleOnEmailChange = useCallback((newValue) => {
    setErrorMessage('');
    setEmail(newValue);
  }, []);

  const handleOnPasswordChange = useCallback((newValue) => {
    const isShortPassword = /^.{0,5}$/.test(newValue);
    setPasswordMessageError('');
    setPassWordInstructions('');

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      if (isShortPassword) {
        setPasswordMessageError('Password must contain at least 6 characters.');
      }
    }, 1000));

    setPassword(newValue);
    setPassWordInstructions(null);
  }, []);

  const handleOnConfirmPasswordChange = useCallback((newValue) => {
    setPasswordConfirmMessageError('');
    setConfirmPassword(newValue);
  }, []);

  const handlePasswordOnFocus = useCallback(() => {
    setPassWordInstructions('Password must contain at least 6 characters.');
  }, []);

  useEffect(() => {
    if (password && confirmPassword && password !== confirmPassword) {
      setPasswordConfirmMessageError('Password and confirmation must match.');
    } else {
      setPasswordConfirmMessageError('');
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    if (!isRegistering && !isLoadingFirebaseAuth && isSignedIn && userData) {
      history.replace({ pathname: ROUTE_HOME, search });
    }
  }, [isLoadingFirebaseAuth, isSignedIn, userData, isRegistering]);

  return (
    <div className="Signup">
      <div className="Signup__container">
        <div className="Signup__container--form">
          <img className="Signup__container--form--logo" src={trashieCoinLogo} alt="logo" />
          <div className="Signup__container--form--content">
            <div className="Signup__container--form--content--pane">
              <GoogleButton onClick={handleSignInWithGoogle} label="REGISTER WITH GOOGLE" />
              <p>OR</p>

              <div className="Signup__container--form--content--pane--form">
                <Input
                  type="text"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleOnEmailChange}
                  errorMessage={errorMessage}
                  disabled={isLoading || isLoadingFirebaseAuth}
                />
                <Input
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={handleOnPasswordChange}
                  errorMessage={passwordMessageError}
                  instructions={passWordInstructions}
                  onFocus={handlePasswordOnFocus}
                  disabled={isLoading || isLoadingFirebaseAuth}
                />
                <Input
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={handleOnConfirmPasswordChange}
                  errorMessage={passwordConfirmMessageError}
                  disabled={isLoading || isLoadingFirebaseAuth}
                />
                <Button
                  type={BUTTON_TYPE.QUATERNARY}
                  size={BUTTON_SIZE.LARGE}
                  onClick={handleRegistration}
                  disabled={isLoading || !email || !password || !confirmPassword ||
                    errorMessage || passwordMessageError || passwordConfirmMessageError}
                >
                  {isLoading || isLoadingFirebaseAuth ? <DotsLoader /> : 'CREATE NEW ACCOUNT'}
                </Button>
                <OptIn
                  className="Signup__container--form--content--pane--form-optIn"
                  checked={optIn}
                  onChange={() => setOptIn(!optIn)}
                  disabled={isLoading || isLoadingFirebaseAuth}
                />
              </div>
            </div>

            <div className="Signup__container--form--content--options">
              <div className="Signup__container--form--content--options--option">
                <p className="Signup__container--form--content--options--option-text">
                  Have an account already?
                </p>
                <Button
                  className="Signup__container--form--content--options--option-link"
                  type={BUTTON_TYPE.LINK}
                  onClick={onFinishProcess}
                >
                  LOG IN
                </Button>
              </div>
              <DisclaimerPublic
                termsUrl={TERMS_OF_SERVICE_URL}
                policyUrl={PRIVACY_POLICY_URL}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
